import React from 'react'
import JumboContentLayout from '@jumbo/components/JumboContentLayout'
import useJumboTheme from '@jumbo/hooks/useJumboTheme'
import { Card, useMediaQuery } from '@mui/material'
import useDefinitionApp from '../hooks/useDefinitionApp'
import DefinitionAppSidebar from '../components/DefinitionAppSidebar/DefinitionAppSidebar'
import DefinitionAppContent from '../components/DefinitionAppContent/DefinitionAppContent'
import { useLocation } from 'react-router-dom'

const DefinitionAppLayout = () => {
    const { theme } = useJumboTheme()
    const { activeDefinition } = useDefinitionApp()
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const location = useLocation()

    // Check if the current path is '/store/definitions'
    const isDefinitionsPage = location.pathname === '/store/definitions'

    // Determine if the sidebar should be hidden on mobile screens
    const shouldHideSidebar = md && !isDefinitionsPage

    // Determine if the content should be displayed
    const shouldHideContent = md && isDefinitionsPage && !activeDefinition

    const layoutOptions = React.useMemo(
        () => ({
            sidebar: {
                sx: {
                    display: md ? (shouldHideSidebar ? 'none' : 'block') : 'block',
                    width: md ? 'auto' : 280,
                    marginRight: 0,
                    borderRight: md ? 'none' : 1,
                    borderRightColor: (theme) => theme.palette.divider,
                },
            },
            wrapper: {
                component: Card,
                sx: {
                    [theme.breakpoints.down('md')]: {
                        flexDirection: 'column',
                    },
                },
            },
            content: {
                sx: {
                    display: shouldHideContent ? 'none' : 'block',
                },
            },
        }),
        [theme, md, activeDefinition, shouldHideSidebar, shouldHideContent, isDefinitionsPage]
    )

    return (
        <JumboContentLayout sidebar={<DefinitionAppSidebar />} layoutOptions={layoutOptions}>
            <DefinitionAppContent style={layoutOptions.content.sx} />
        </JumboContentLayout>
    )
}

export default DefinitionAppLayout
