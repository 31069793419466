import React from 'react'
import { Navigate } from 'react-router-dom'
import InventoryGrid from '../pages/store/inventory'
import UpdateInventory from '../pages/store/inventory/components/UpdateInventory'
import AddInventory from '../pages/store/inventory/components/AddInventory'
import Page from '@jumbo/shared/Page'
import DefinitionApp from 'app/pages/store/definition/DefinitionApp'
import Shipments from 'app/pages/store/shipments'
import AddSupplier from 'app/pages/store/suppliers/suppliersGrid/components/AddSupplier'
import UpdateSupplier from 'app/pages/store/suppliers/suppliersGrid/components/UpdateSupplier'
import ManageSales from 'app/pages/store/sales/ManageSales'
import TxnView from 'app/pages/store/sales/components/txnView/TxnView'
import Customers from '../pages/store/customer/Customers'
import AddCustomer from 'app/pages/store/customer/components/customer/components/AddCustomer'
import BranchSettings from '../pages/store/settings/BranchSettings'
import OrderView from 'app/pages/store/shipments/components/orders/components/orderView/OrderView'
import AddDraftOrder from 'app/pages/store/shipments/components/orders/DraftOrders/components/AddDraftOrder'
import UpdateDraftOrder from 'app/pages/store/shipments/components/orders/DraftOrders/components/UpdateDraftOrder'
import UpdateCustomer from 'app/pages/store/customer/components/customer/components/UpdateCustomer'
import UpdateDraftPayment from 'app/pages/store/customer/components/payments/DraftPayments/components/updateDraftPayment/UpdateDraftPayment'
import AddDraftPayment from 'app/pages/store/customer/components/payments/DraftPayments/components/addDraftPayment/AddDraftPayment'
import AddDraftSuppliersPayment from 'app/pages/store/suppliers/DraftSupplierPayment/components/AddDraftSuppliersPayment'
import UpdateDraftSuppliersPayment from 'app/pages/store/suppliers/DraftSupplierPayment/components/UpdateDraftSuppliersPayment'
import Suppliers from 'app/pages/store/suppliers/Suppliers'

const storeRoutes = [
    {
        path: '/store',
        element: <Navigate to={'/store/inventory'} />,
    },
    {
        path: '/store/inventory',
        element: <Page component={InventoryGrid} />,
    },
    {
        path: '/store/inventory/edit/:inventoryId',
        element: <Page component={UpdateInventory} />,
    },
    {
        path: '/store/inventory/addInventory',
        element: <Page component={AddInventory} />,
    },
    {
        path: ['/store/sales/', '/store/sales/:selectedTab'],
        element: <Page component={ManageSales} />,
    },
    {
        path: ['/store/sales/all-transaction/details/:posTxId'],
        element: <Page component={TxnView} />,
    },
    {
        path: ['/store/shipments/', '/store/shipments/:selectedTab'],
        element: <Page component={Shipments} />,
    },
    {
        path: ['/store/suppliers/', '/store/suppliers/:selectedTab'],
        element: <Page component={Suppliers} />,
    },
    {
        path: ['/store/suppliers/supplier/addsupplier'],
        element: <Page component={AddSupplier} />,
    },
    {
        path: '/store/suppliers/supplier/edit/:supplierId',
        element: <Page component={UpdateSupplier} />,
    },
    {
        path: ['/store/suppliers/draftsupplierspayments/adddraftsupplierspayments'],
        element: <Page component={AddDraftSuppliersPayment} />,
    },
    {
        path: ['/store/suppliers/draftsupplierspayments/edit/:supplierPaymentId'],
        element: <Page component={UpdateDraftSuppliersPayment} />,
    },
    {
        path: ['/store/shipments/orders/details/:orderId/:onlineShipment'],
        element: <Page component={OrderView} />,
    },
    {
        path: ['/store/customers/', '/store/customers/:tabTitle'],
        element: <Page component={Customers} />,
    },
    {
        path: ['/store/customers/addcustomer'],
        element: <Page component={AddCustomer} />,
    },
    {
        path: ['/store/customers/edit/:customerId'],
        element: <Page component={UpdateCustomer} />,
    },
    {
        path: ['/store/draftpayments/edit/:customerPaymentId'],
        element: <Page component={UpdateDraftPayment} />,
    },
    {
        path: ['/store/customers/draftpayments/addpayment'],
        element: <Page component={AddDraftPayment} />,
    },
    {
        path: ['/store/settings', '/store/settings/:tabTitle', '/store/settings/:tabTitle/:selectedId'],
        element: <Page component={BranchSettings} />,
    },
    {
        path: ['/store/definitions', '/store/definitions/:category', '/store/definitions/:category/:id'],
        element: <Page component={DefinitionApp} />,
    },
    {
        path: ['/store/shipments/draftorders/adddraftorders'],
        element: <Page component={AddDraftOrder} />,
    },
    {
        path: '/store/shipments/draftorders/edit/:stockShipmentId/:selectedTab',
        element: <Page component={UpdateDraftOrder} />,
    },
]

export default storeRoutes
