import React, { useCallback, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminDashboardSummary } from 'app/redux/store/features/dashboard'
import HeaderCard from './admin/headerbar/HeaderCard'
import BannerLeft from './admin/bannerbar/BannerLeft'
import BannerRight from './admin/bannerbar/BannerRight'
import LeftInfoPanel from './admin/infoSection/leftInfoPanel/LeftInfoPanel'
import RightInfoPanel from './admin/infoSection/rightInfoPanel/RightInfoPanel'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import util from 'app/utils/permissions/util-permissions'
import { CanLogin } from 'app/constants/Permissions'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'

const AdminDashboard = () => {
    const { authUser } = useJumboAuth()
    const navigate = useNavigate()
    const canLogin = util.hasPermission(authUser, CanLogin)
    const dispatch = useDispatch()
    const { loading, error } = useSelector((state) => state.dashboard)
    const { enqueueSnackbar } = useSnackbar()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)

    useEffect(() => {
        if (!authUser || !canLogin) {
            navigate('/profile/signout')
        }
    }, [authUser, canLogin, navigate])

    useEffect(() => {
        try {
            setApiCallInProgress(true)
            dispatch(getAdminDashboardSummary())
        } catch (error) {
            setApiCallInProgress(false)
            handleError(error)
        }
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    const handleError = useCallback(
        (error) => {
            if (error.status === '401') {
                navigate('/profile/signout')
            } else if (error.detail) {
                enqueueSnackbar(error.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar(error, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            }
        },
        [enqueueSnackbar, navigate]
    )

    return (
        <Grid container spacing={1.5}>
            <>
                <Grid item xs={12} sm={12} lg={12}>
                    <HeaderCard />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <BannerLeft />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <BannerRight />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <LeftInfoPanel />
                </Grid>
                <Grid item xs={12} sm={6} lg={6}>
                    <RightInfoPanel />
                </Grid>
            </>
        </Grid>
    )
}
export default AdminDashboard
