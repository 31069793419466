import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import OnlineTransaction from './components/OnlineTransaction'
import AllTransaction from './components/AllTransaction'
import { useJumboTheme } from '@jumbo/hooks'
import { useMediaQuery, Box } from '@mui/material'
import Div from '@jumbo/shared/Div'

const ManageSales = () => {
    const navigate = useNavigate()
    const { selectedTab } = useParams()
    const [tabTitle, setTabTitle] = React.useState(selectedTab ? selectedTab : 'approved-transactions')
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    const onTabSelectionChanged = (event, newValue) => {
        setTabTitle(newValue)
        navigate(`/store/sales/${newValue}`)
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                noWrapper
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Sales
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <Tabs
                    value={tabTitle}
                    onChange={onTabSelectionChanged}
                    indicatorColor='primary'
                    textColor='primary'
                    variant='scrollable'
                    scrollButtons='auto'
                    allowScrollButtonsMobile
                    sx={{
                        bgcolor: 'background.default',
                        mt: 2,
                        mb: 0,
                        ml: 3.1,
                        mr: 3.1,
                        '.MuiTabs-scrollButtons': {
                            // Styling for scroll buttons
                            '&.Mui-disabled': {
                                opacity: 0.3, // Ensure disabled buttons are visible but faded
                            },
                        },
                    }}>
                    <Tab
                        label='Approved Transactions'
                        value='approved-transactions'
                        sx={{ width: min ? '125px' : '188px', textTransform: 'capitalize' }}
                    />
                    <Tab
                        label='Draft Transactions'
                        value='draft-transactions'
                        sx={{ width: min ? '125px' : '188px', textTransform: 'capitalize' }}
                    />
                </Tabs>
                <Div>
                    {tabTitle === 'approved-transactions' && (
                        <Box paddingLeft={2} paddingRight={2}>
                            <AllTransaction />
                        </Box>
                    )}
                </Div>
                <Div>
                    {tabTitle === 'draft-transactions' && (
                        <Box paddingLeft={2} paddingRight={2}>
                            <OnlineTransaction />
                        </Box>
                    )}
                </Div>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default ManageSales
