import React from 'react'
import Div from '@jumbo/shared/Div'
import { IconButton, useMediaQuery } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom'
import { useJumboTheme } from '@jumbo/hooks'

const ActiveDefinitionHeader = () => {
    const navigate = useNavigate()
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    const handleCloseConversation = () => {
        navigate('/store/definitions')
    }

    return (
        <Div
            sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                borderBottom: 1,
                borderBottomColor: 'divider',
                p: min ? (theme) => theme.spacing(2, 3) : '',
            }}>
            {min && (
                <IconButton aria-label='Previous' sx={{ mr: 2 }} onClick={handleCloseConversation}>
                    <ArrowBackIcon />
                </IconButton>
            )}
        </Div>
    )
}

export default ActiveDefinitionHeader
