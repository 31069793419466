import React from 'react'
import ActiveDefinitionFooter from './ActiveDefinitionFooter'
import ActiveDefinitionHeader from './ActiveDefinitionHeader'
import ActiveDefinitionView from './ActiveDefinitionView/ActiveDefinitionView'
import { useParams } from 'react-router-dom'
import ChartOfAccount from '../AccountingDefinitionList/components/chartofaccount/ChartOfAccount'
import { useJumboTheme } from '@jumbo/hooks'
import { useMediaQuery } from '@mui/material'

const ActiveDefinition = React.forwardRef(({ onRefresh }, ref) => {
    const { id } = useParams()
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const md = useMediaQuery(theme.breakpoints.down('md'))
    const min = sm || md

    if (!id) {
        return <React.Fragment>{!min && <ChartOfAccount />}</React.Fragment>
    } else {
        return (
            <React.Fragment>
                <ActiveDefinitionHeader />
                <ActiveDefinitionView />
                <ActiveDefinitionFooter />
            </React.Fragment>
        )
    }
})

ActiveDefinition.defaultProps = {
    onRefresh: () => {},
}

export default ActiveDefinition
