import React from 'react'
import GroupIcon from '@mui/icons-material/Group'
import InventoryIcon from '@mui/icons-material/Inventory'
import GroupsIcon from '@mui/icons-material/Groups'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import LockResetIcon from '@mui/icons-material/LockReset'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'
import ReportIcon from '@mui/icons-material/Report'
import DashboardIcon from '@mui/icons-material/Dashboard'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit'
import EditIcon from '@mui/icons-material/Edit'
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined'
import { SiAdobeaudition } from 'react-icons/si'
import StoreIcon from '@mui/icons-material/Store'
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'
import LogoutIcon from '@mui/icons-material/Logout'
import util from '../../../../utils/permissions/util-permissions'

import {
    CanViewUsers,
    CanViewPermissions,
    CanViewCompanyList,
    CanViewInventoryList,
    CanViewSales,
    CanViewCustomers,
    CanViewBranchDefinitions,
    CanViewShipments,
    CanViewSuppliers,
    CanViewBranchSettings,
    CanViewBranchReports,
    CanViewBranchLocations,
    CanViewGlobalConfig,
    CanLogin,
    CanViewSecurity,
    CanViewStore,
    CanViewSettings,
    canViewCompany,
    canViewAuditLogs,
    canViewTransfers,
} from '../../../../constants/Permissions'

const dashboardMenus = [
    {
        uri: '/dashboard',
        label: 'sidebar.menuItem.dashboard',
        type: 'nav-item',
        icon: <DashboardIcon sx={{ fontSize: 20 }} />,
    },
]

function getSecurityMenus(authSlice) {
    let sideBar = []
    if (authSlice && authSlice.permissions) {
        let permission = util.hasPermission(authSlice, CanViewUsers)
        if (permission) {
            sideBar.push({
                uri: '/security/users',
                label: 'sidebar.menuItem.users',
                type: 'nav-item',
                icon: <GroupIcon sx={{ fontSize: 20 }} />,
            })
        }
        permission = util.hasPermission(authSlice, CanViewPermissions)
        if (permission) {
            sideBar.push({
                uri: '/security/permissions',
                label: 'sidebar.menuItem.permissions',
                type: 'nav-item',
                icon: <LockResetIcon sx={{ fontSize: 20 }} />,
            })
        }
        return sideBar
    }
}

function getCompanyMenus(authSlice) {
    let sideBar = []
    if (authSlice && authSlice.permissions) {
        let permission = util.hasPermission(authSlice, canViewAuditLogs)
        if (permission) {
            sideBar.push({
                uri: '/company/auditlogs',
                label: 'sidebar.menuItem.auditlogs',
                type: 'nav-item',
                icon: <SiAdobeaudition sx={{ fontSize: 20 }} />,
            })
        }
        permission = util.hasPermission(authSlice, canViewTransfers)
        if (permission) {
            sideBar.push({
                uri: '/company/transfers',
                label: 'sidebar.menuItem.transfers',
                type: 'nav-item',
                icon: <TransferWithinAStationIcon sx={{ fontSize: 20 }} />,
            })
        }
        return sideBar
    }
}

function getStoreMenus(authSlice) {
    let storeMenus = []
    if (authSlice && authSlice.permissions) {
        let permission = util.hasPermission(authSlice, CanViewInventoryList)
        if (permission) {
            storeMenus.push({
                uri: '/store/inventory',
                label: 'sidebar.menuItem.inventory',
                type: 'nav-item',
                icon: <InventoryIcon sx={{ fontSize: 20 }} />,
            })
        }
        permission = util.hasPermission(authSlice, CanViewCustomers)
        if (permission) {
            storeMenus.push({
                uri: '/store/customers',
                label: 'sidebar.menuItem.customers',
                type: 'nav-item',
                icon: <GroupsIcon sx={{ fontSize: 20 }} />,
            })
        }
        permission = util.hasPermission(authSlice, CanViewSales)
        if (permission) {
            storeMenus.push({
                uri: '/store/sales',
                label: 'sidebar.menuItem.sales',
                type: 'nav-item',
                icon: <PointOfSaleIcon sx={{ fontSize: 20 }} />,
            })
        }
        permission = util.hasPermission(authSlice, CanViewSuppliers)
        if (permission) {
            storeMenus.push({
                uri: '/store/suppliers',
                label: 'sidebar.menuItem.suppliers',
                type: 'nav-item',
                icon: <StoreIcon sx={{ fontSize: 20 }} />,
            })
        }
        permission = util.hasPermission(authSlice, CanViewShipments)
        if (permission) {
            storeMenus.push({
                uri: '/store/shipments',
                label: 'sidebar.menuItem.shipments',
                type: 'nav-item',
                icon: <LocalShippingIcon sx={{ fontSize: 20 }} />,
            })
        }
        permission = util.hasPermission(authSlice, CanViewBranchSettings)
        if (permission) {
            storeMenus.push({
                uri: '/store/settings',
                label: 'sidebar.menuItem.settings',
                type: 'nav-item',
                icon: <SettingsApplicationsIcon sx={{ fontSize: 20 }} />,
            })
        }
        permission = util.hasPermission(authSlice, CanViewBranchDefinitions)
        if (permission) {
            storeMenus.push({
                uri: '/store/definitions',
                label: 'sidebar.menuItem.definitions',
                type: 'nav-item',
                icon: <AddBusinessIcon sx={{ fontSize: 20 }} />,
            })
        }
        return storeMenus
    }
}

function getStoreReportMenus(authSlice) {
    let sideBar = []
    if (authSlice && authSlice.permissions) {
        let permission = util.hasPermission(authSlice, CanViewBranchReports)
        if (permission) {
            sideBar.push({
                uri: '/reports',
                label: 'sidebar.menuItem.reports',
                type: 'nav-item',
                icon: <ReportIcon sx={{ fontSize: 20 }} />,
            })
        }
        return sideBar
    }
}

function getSettingMenus(authSlice) {
    let sideBar = []
    if (authSlice && authSlice.permissions) {
        let permission = util.hasPermission(authSlice, CanViewCompanyList)
        if (permission) {
            sideBar.push({
                uri: '/settings/companies',
                label: 'sidebar.menuItem.companies',
                type: 'nav-item',
                icon: <AddBusinessIcon sx={{ fontSize: 20 }} />,
            })
        }
        permission = util.hasPermission(authSlice, CanViewBranchLocations)
        if (permission) {
            sideBar.push({
                uri: '/settings/branches',
                label: 'sidebar.menuItem.branches',
                type: 'nav-item',
                icon: <VerticalSplitIcon sx={{ fontSize: 20 }} />,
            })
        }
        permission = util.hasPermission(authSlice, CanViewGlobalConfig)
        if (permission) {
            sideBar.push({
                uri: '/settings/configuration',
                label: 'sidebar.menuItem.configuration',
                type: 'nav-item',
                icon: <WidgetsOutlinedIcon sx={{ fontSize: 20 }} />,
            })
        }

        return sideBar
    }
}
function getProfileMenus(authSlice) {
    let sideBar = []
    if (authSlice && authSlice.permissions) {
        // Profile and logout always available
        sideBar.push({
            uri: '/profile/editprofile',
            label: 'sidebar.menuItem.profile',
            type: 'nav-item',
            icon: <EditIcon sx={{ fontSize: 20 }} />,
        })
        sideBar.push({
            uri: '/profile/signout',
            label: 'sidebar.menuItem.logout',
            type: 'nav-item',
            icon: <LogoutIcon sx={{ fontSize: 20 }} />,
        })
        return sideBar
    }
}

export const getSidebarMenus = (authSlice) => {
    let sidebarNavs = []
    if (authSlice && authSlice.permissions) {
        let permission = util.hasPermission(authSlice, CanLogin)
        if (permission) {
            sidebarNavs.push({
                label: 'sidebar.menu.home',
                type: 'section',
                children: dashboardMenus, // CanViewUserDashboard=1, CanViewAdminDashboard=2
            })
        }
        permission = util.hasPermission(authSlice, CanViewSecurity)
        if (permission) {
            sidebarNavs.push({
                label: 'sidebar.menu.security',
                type: 'section',
                children: getSecurityMenus(authSlice), // Roles and Permissions
            })
        }
        permission = util.hasPermission(authSlice, canViewCompany)
        if (permission) {
            sidebarNavs.push({
                label: 'sidebar.menu.company',
                type: 'section',
                children: getCompanyMenus(authSlice), // Roles and Permissions
            })
        }
        permission = util.hasPermission(authSlice, CanViewStore)
        if (permission) {
            sidebarNavs.push({
                label: 'sidebar.menu.store',
                type: 'section',
                children: getStoreMenus(authSlice), // [inventoryListMenus, invAdjustmentMenus, salesMenus, shipmentsMenus],
            })
        }
        permission = util.hasPermission(authSlice, CanViewBranchReports)
        if (permission) {
            sidebarNavs.push({
                label: 'sidebar.menu.reports',
                type: 'section',
                children: getStoreReportMenus(authSlice),
            })
        }
        permission = util.hasPermission(authSlice, CanViewSettings)
        if (permission) {
            sidebarNavs.push({
                label: 'sidebar.menu.setting',
                type: 'section',
                children: getSettingMenus(authSlice),
            })
        }
        sidebarNavs.push({
            label: 'sidebar.menu.profile',
            type: 'section',
            children: getProfileMenus(authSlice),
        })
    }
    return sidebarNavs
}
